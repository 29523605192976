<template>
  <van-nav-bar
  title="个人信息管理"
  left-arrow
  @click-left="onGoBack"
  />
  <div>
    <van-cell-group class="group" border=true>
      <van-cell icon="user-o" is-link @click="showPopup('text','修改姓名：','user_name')"><span>{{dic_user_info.user_name}}</span>
        <template #right-icon>
         <van-icon name="edit" class="edit-icon" />
        </template>
      </van-cell>
      
      <van-cell   icon="phone-o"  is-link @click="showPopup('text','修改手机号：','phone_num')">手机号：{{dic_user_info.phone_num}}
        <template #right-icon>
         <van-icon name="edit" class="edit-icon" />
        </template>
      </van-cell>
      <van-cell icon="closed-eye" title="密码" is-link @click="showPopup('password','修改密码：','password')">
        <template #right-icon>
         <van-icon name="edit" class="edit-icon" />
        </template>
      </van-cell>
    </van-cell-group>
    
    <van-cell-group class="group" border=true>
    
    </van-cell-group>
    <van-button type="primary" round block style="width:50%;margin:50px auto 0 auto">保存修改</van-button>
    <van-popup round v-model:show="show"  :style="{valign:middle,fontsize:'18pm',width:'90%',height: '150px' }">
      <div style="display:block;margin-top:30px">
        <van-cell-group inset style="valign:middle;fontsize:18px">
         <van-field v-model="input_value" :type="pop_def.type" :label="pop_def.label"  />
        </van-cell-group>
        <van-button type="primary" round block style="width:50%;margin:5px auto 0 auto" @click="changevalue(popup_name)">确定修改</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref,reactive,getCurrentInstance,onMounted, watch} from 'vue';
import { useUserStore } from '@/store/user.ts'
import { Picker } from 'vant';
import { Popup } from 'vant';
import {Toast} from 'vant';
const base_url=process.env.VUE_APP_API_PORT_BASE_URL
export default {
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Picker.Toast]: Toast,
  },

  setup() {
     
    const show = ref(false);
    const input_value=ref('');
    const popup_name=ref('');

    const userStore = useUserStore()

    let { proxy } = getCurrentInstance();

    const showPopup = (type,label,name) => {
      pop_def.type=type;
      pop_def.label=label;
      popup_name.value=name
      show.value = true;
      console.log(popup_name.value)
    };
    
    let dic_user_info=reactive({});
    let pop_def=reactive({
      type:'',
      label:''
    });
    
    onMounted(()=>{
      getuserinfo()

    });

    const getuserinfo=()=>{
      proxy.$axios.post(base_url+'/user/getUserByUsernameAndPhonenum',
      {
        userName:userStore.userInfo.name,
        userPhoneNum:userStore.userInfo.phone
      }).then(res=>{
        console.log(res.data.data)
        dic_user_info.user_name=res.data.data.userName
        dic_user_info.phone_num=res.data.data.userPhoneNum
      }).catch(err=>{
        console.log(err)
      })
    };
    
    const changevalue=(val)=>{
      //input_value=value;
      dic_user_info[val]=input_value.value;
      console.log(dic_user_info[val]);
      input_value.value='';
      show.value = false;
    }
    
    //onMounted(changevalue);
    //watch(dic_user_info,changevalue);

    const columns = ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'];

    const onConfirm = (value, index) => {
      //Toast(`当前值: ${value}, 当前索引: ${index}`);
      show.value = false;
    };
    const onChange = (value, index) => {
      Toast(`当前值: ${value}, 当前索引: ${index}`);
    };
    const onCancel = () => Toast('取消');

    return {
      pop_def,
      dic_user_info,
      show,
      popup_name,
      showPopup,
      input_value,
      changevalue,
      columns,
      onChange,
      onCancel,
      onConfirm,
      getuserinfo,
    };
  },

	methods: {


  }
  
};
</script>

<style lang="less">

.edit-icon {
    font-size: 16px;
    line-height: inherit;
  }

</style>
